<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="custom-card-title">
            <i class="fas fa-language"></i>
            <span>{{ $t("translationSettings") }}</span>
          </h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>

    <WeCard>
      <WeTable
        v-bind:index="false"
        v-bind:data.sync="translationSetting.list"
        v-bind:columns="getColumns"
      ></WeTable>
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: false,
    };
  },
  methods: {
    ...mapActions("translationSetting", ["update", "getList"]),
    submitForm() {
      this.submit = true;
      this.update({
        form: this.data,
        onFinish: () => {
          this.submit = false;
          this.$toast.success("İşlem Başarılı");
        },
      });
    },
    getLanguageLines() {
      this.getList({
        onFinish: () => {
          this.ready = true;
        },
      });
    },
  },
  computed: {
    ...mapState(["translationSetting", "session"]),
    availableLocales() {
      let locales = this.session?.config["site.available_locales"];

      if (locales) {
        locales = JSON.parse(locales);
      } else {
        locales = ["tr"];
      }

      return locales;
    },
    getColumns() {
      let result = [
        {
          name: "var",
          th: "Değişken",
          type: "html",
          width: "25%",
          sortable: false,
        },
      ];
      const locales = this.availableLocales;

      if (locales && locales.length) {
        locales.forEach((lang) => {
          result.push({
            name: lang,
            th: lang.toLocaleUpperCase(),
            type: "input",
            sortable: false,
          });
        });
      }

      return result;
    },
  },
  mounted() {
    this.getLanguageLines();
  },
};
</script>